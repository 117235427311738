.bodyListContainer {
  width: 100%;
  border-bottom: 1px solid #e7e6e6a1;
  border-top: 1px solid #e7e6e6a1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px !important;
}
.centerContent {
  justify-content: center;
  height: 75%;
}
.appointmentListContainer {
  
  height: 100%;
  width: 100%;
  padding: 10px 0 40px 40px;
  overflow: auto;
}
.buttonSendContainer{
  display: flex;
  justify-content: flex-end;
  padding: 15px 0 5px 0;
}
.buttonSendEmail{
  background-color:#ABD838;
  border: #ABD838;
}
.buttonSendEmail:hover{
  background-color:#ABD838;
  border: #ABD838;
}
.buttonSendEmail:focus{
  background-color:#9fcf24;
  border: #5b7c06;
}
.addCommaSeparator:last-child {
  display: none;
}
.appointmentList {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 52vh;
}
.descriptionItemContainer {
  width: 100%;
  padding: 10px 40px 0px 40px;
}
.descriptionItemsContent {
  padding: 5px;
}
.descriptionItemCard {
  width: 100%;
  border: 1px solid #e4e9f3;
}
.descriptionItemTitle {
  padding: 10px;
  color: #6b7cff;
  font-weight: bold;
  border-bottom: 1px solid #e4e9f3;
}
.descriptionItemFooter {
  border-top: 1px solid #e4e9f3;
  padding: 10px;
}
.descriptionItemBody {
  padding: 10px;
}
.dotsNomenclaturePatient {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #5974af;
}
.dotsNomenclatureDoctor {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #f24141;
}
.nomenclatureContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paragraphConnectionNomenclature {
  margin: 0px 0px 0px 10px;
}
.paragraphTimelineDate,
.paragraphStatus,
.paragraphGeneralInfo {
  color: #838383;
  margin-bottom: 2px;
  font-size: 12px;
}
.paragraphTimelineText {
  color: #838383;
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: bolder;
}
.paragraphRatings {
  color: #838383;
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: bolder;
}

.paragraphRatingsProblemNames {
  color: #6b7cff;
  font-weight: bolder;
  font-size: 12px;
  margin-bottom: 2px;
}
.paragraphRatingsComment {
  color: #2d3f8c;
  font-weight: bolder;
  font-size: 12px;
  margin-bottom: 2px;
}
.paragraphTimelineTitle {
  font-weight: bolder;
  font-size: 12px;
  margin-bottom: 0px;
  color: #6b7cff;
}
.spanGeneralInfoData,
.spanGeneralInfoEmail {
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
}
.spanGeneralInfoEmail {
  color: #6b7cff;
}
.dotTimeline {
  width: 8px;
  height: 8px;
  background: #6b7cff;
  border-radius: 50%;
}

.textAlignResponsive {
  text-align: right;
}
.appointmentListHeaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e4e9f3;
  padding: 10px 20px;
  font-weight: 500;
  width: 100%;
  color: #2d3e8c94;
  font-size: 14px;
}

.headerListStateAndDoctorContainer {
  width: 35%;
  display: flex;
}
.headerListPatientAndSpeciallityContainer {
  width: 50%;
  display: flex;
}
.headerListHourContainer {
  width: 5%;
}
.headerListActionContainer {
  width: 10%;
  text-align: center;
}

.headerListState {
  width: 50%;
  display: flex;
  justify-content: center;
  text-align: center;
}
.headerListDoctor {
  width: 50%;
}
.headerListSpeciallity,
.headerListPatient {
  width: 50%;
  padding-left: 50px;
}
.listHeaderInfoContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0px 20px;
}
.listHeaderGroupedInfoContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 20px 0px 20px;
}
.headerCardContainer {
  display: flex;
  background: #f9f9f9;
  border-radius: 25px;
  padding: 8px 0px;
  color: #707070;
  font-size: 14px;
  justify-content: center;
}
.headerCardContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
}
.headerCardDot {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50%;
}
.headerCardValue {
  margin-left: 4px;
  font-weight: bold;
}
.titleSubHeader {
  font-weight: bold;
  color: #6b7cff;
}

.filterContainer {
  padding-left: 5px;
  padding-right: 1em;
}
.checkboxSent{
  margin-left: 10px;
}
@media screen and (max-width: 1024px) {
  .headerListStateAndDoctorContainer {
    width: 25%;
  }
  .headerListPatientAndSpeciallityContainer {
    width: 55%;
  }
  .headerListActionContainer,
  .headerListHourContainer {
    width: 10%;
  }
  .headerListDoctor span {
    display: none;
  }
}
.buttonContainer {
  padding-left: 5px;
  padding-right: 5px;
}

.invoiceButton {
  background-color: #5974AF;
  border: #5974AF;
}

.invoiceButton:hover {
  background-color: #3a4d75;
  border: #3a4d75;
}

.invoiceButton:focus {
  background-color: #6688d3;
  border: #6688d3;
}

@media (max-width: 800px) {
  .headerListPatientAndSpeciallityContainer,
  .headerListStateAndDoctorContainer {
    width: 40%;
  }
  .headerListActionContainer {
    width: 20%;
  }

  .headerListState {
    justify-content: initial;
  }
  .headerListState,
  .headerListDoctor {
    text-align: left;
  }

  .headerListSpeciallity,
  .headerListPatient {
    padding-left: 20px;
  }
  .headerListPatientAndSpeciallityContainer {
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .centerContent {
    height: 70%;
  }
  .appointmentListContainer {
    padding: 0px;
  }
  .headerListStateAndDoctorContainer {
    width: 35%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 450px) {
  .appointmentListHeaderContainer {
    padding: 10 30px;
  }
  .appointmentList {
    height: 40vh;
  }
  .headerCardContainer {
    display: none;
  }
  .listHeaderInfoContainer {
    padding: 10px 10px 0px 10px;
  }
  .checkBoxSubHeader {
    font-size: 14px;
  }
  .titleSubHeader {
    text-align: center;
  }
}

@media screen and (max-width: 322px) {
  .checkBoxSubHeader {
    font-size: 11px;
  }
}
