body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(192, 189, 189, 0.658);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(187, 183, 183);
}
.ant-progress-inner {
  width: 90% !important;
}

.ant-progress-text {
  margin-left: -3px;
}

.ant-progress-bg {
  background-color: #52c41a !important;
}
/* 

.ant-modal-content {
  margin: 0 0 0 -375px !important;
  width: 1300px !important;
} */
