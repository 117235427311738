.containerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 750px;
}

.description {
  width: 18%;
  text-align: center;
}

.description p {
  color: #a0aec0;
}

.description p:nth-child(1) {
  margin: 35px 0 0 -20px;
}

.description p:nth-child(2) {
  margin: 20px 0 0;
}

.uploadButton {
  height: 45px;
  width: 272px;
}

.sendButton {
  border-radius: 2px;
  height: 40px;
  width: 237px;
  margin-top: 50px;
}

.infoButton {
  margin: 10px 0 -40px 0;
}
